<template>
  <div class="container">
    <div class="text-end p-3">
      <c-primary-button to="/admin/configuraciones/publicaciones-tipos/crear">
        Crear tipo de publicacion
      </c-primary-button>
    </div>
    <c-card title="Tipos de publicación">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Tipo de publicacion</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="tipo in tipos" :key="tipo.id">
            <td>{{ tipo.name }}</td>
            <td class="text-nowrap">
              <router-link
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                :to="`/admin/configuraciones/publicaciones-tipos/${tipo.id}/editar`"
                title="Editar"
                ><i class="fa-solid fa-pen"></i
              ></router-link>
              <a
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                href="javascript:void(0)"
                @click="deleteTipo(tipo.id)"
                title="Eliminar"
                ><i class="fa-solid fa-trash-can"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import { all, remove } from "@/services/tipos";
import { showNotification } from "@/services/notification";

export default {
  components: {
    CCard,
    CPrimaryButton,
  },
  data() {
    return {
      tipos: [],
    };
  },
  mounted() {
    this.getTipos();
  },
  methods: {
    getTipos() {
      all()
        .then((response) => {
          this.tipos = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar los tipos");
        });
    },
    deleteTipo(id) {
      const removeRecord = confirm("¿Estás seguro de eliminar el registro?");
      if (!removeRecord) {
        return;
      }
      remove(id).then(() => {
        showNotification(204, "success", "El registro fue eliminado.");
        this.getTipos();
      });
    },
  },
};
</script>
